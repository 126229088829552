import React from 'react';
import pt from 'prop-types';
import { Avatar, Stack, Typography } from '@metarouter-private/mr-mui';
import StarterKits from '../../starterkits/starterkits-state';

const IntegrationName = ({ id }) => {
  const { kits, kitById } = StarterKits();

  // retrieve integration starterkit from starterkits array
  const starterkit = kitById(kits, id);

  let logo;

  try {
    logo = require(`./logos/${id}.svg`).default;
  } catch (error) {
    return (
      <Stack alignItems="center" direction="row" spacing={1}>
        <Avatar sx={{ width: 32, height: 32 }}>{id.charAt(0).toUpperCase()}</Avatar>
        <Typography component="span">{id}</Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Avatar src={logo} sx={{ width: 32, height: 32 }} />
      <Typography component="span">{starterkit?.friendlyName}</Typography>
    </Stack>
  );
};

IntegrationName.propTypes = {
  id: pt.string.isRequired,
};

export default IntegrationName;
