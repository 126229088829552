import React from 'react';
import propTypes from 'prop-types';

import IntegrationName from 'app/components/integrations/integration-name';

export default function IntegrationCell({ integrationName }) {
  return <IntegrationName id={integrationName} />;
}

IntegrationCell.propTypes = {
  integrationName: propTypes.string.isRequired,
};
