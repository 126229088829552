import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import { TimeAgo, FormattedDate } from '@metarouter-private/mr-mui';

export default function TimestampCell({ timestamp }) {
  const timestampDate = new Date(timestamp);

  return (
    <Stack direction="column">
      <TimeAgo TypographyProps={{ variant: 'subtitle2' }} date={timestampDate} />

      <FormattedDate TypographyProps={{ variant: 'caption' }} date={timestampDate} />
    </Stack>
  );
}

TimestampCell.propTypes = {
  timestamp: PropTypes.string.isRequired,
};
