import React from 'react';
import pt from 'prop-types';
import { Stack, Typography } from '@metarouter-private/mr-mui';
import { UncontrolledMrCodeEditor } from '@ion/components';
import { ROWS, DEFAULT_VALUES } from '../fixtures/code-editor';
import { useCreateSchemaContext } from '../create/CreateSchemaPageContextProvider';

const SchemaCodeEditor = ({ name, onChange, defaultValue, title }) => {
  const { jsonSchemaError } = useCreateSchemaContext();

  const errors = {
    jsonSchema: jsonSchemaError,
  };

  return (
    <Stack spacing={1}>
      <Typography>{title}</Typography>
      <UncontrolledMrCodeEditor
        name={title}
        lang="json"
        defaultValue={defaultValue || DEFAULT_VALUES[name]}
        rows={ROWS}
        onChange={onChange}
        errorMsg={errors[name]}
      />
    </Stack>
  );
};

SchemaCodeEditor.propTypes = {
  name: pt.string.isRequired,
  onChange: pt.func,
  defaultValue: pt.string,
  title: pt.string.isRequired,
};

export default SchemaCodeEditor;
