import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';

const CreateSchemaContext = createContext(null);

export default function CreateSchemaPageContextProvider({ children }) {
  const [jsonSchemaError, setJsonSchemaError] = React.useState(null);
  return (
    <CreateSchemaContext.Provider value={{ jsonSchemaError, setJsonSchemaError }}>
      {children}
    </CreateSchemaContext.Provider>
  );
}

export function useCreateSchemaContext() {
  return useContext(CreateSchemaContext);
}

CreateSchemaPageContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
