import { useCallback } from 'react';
import { debounce as _debounce } from 'lodash';
import Ajv from 'ajv';
import { useCreateSchemaContext } from '../create/CreateSchemaPageContextProvider';

export const useValidateSchema = () => {
  const { setJsonSchemaError } = useCreateSchemaContext();

  const ajv = new Ajv({ strict: false });

  function validateSchema(event) {
    try {
      const json = JSON.parse(event.target.value);
      ajv.compile(json);
    } catch (e) {
      console.error(e);
      setJsonSchemaError('Invalid JSON Schema');
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidate = useCallback(
    _debounce(event => {
      validateSchema(event);
    }, 2000),
    []
  );

  const handleChange = event => {
    setJsonSchemaError(null);
    debouncedValidate(event);
  };

  return handleChange;
};
