import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@metarouter-private/mr-mui';

import { useOpen } from '@ion/components';

import RequestDrawer from './RequestDrawer';
import isEmpty from 'lodash/isEmpty';

const LABEL = 'View';

export default function RequestCell({ requestHeaders, requestBody }) {
  const [isOpen, onOpen, onClose] = useOpen();
  const disabled = isEmpty(requestHeaders) && isEmpty(requestBody);

  return (
    <>
      <Button variant="text" onClick={onOpen} disabled={disabled}>
        {LABEL}
      </Button>

      {isOpen && (
        <RequestDrawer requestHeaders={requestHeaders} requestBody={requestBody} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
}

RequestCell.propTypes = {
  requestHeaders: PropTypes.string.isRequired,
  requestBody: PropTypes.string.isRequired,
};
