import React from 'react';
import BaseSchemaPage from '../shared';
import CreateSchemaPageContextProvider from './CreateSchemaPageContextProvider';

export default function CreateSchemaPage() {
  return (
    <CreateSchemaPageContextProvider>
      <BaseSchemaPage
        title="New Schema"
        submitHandler={function createSchemaMutationPlaceholder() {
          return;
        }}
      />
    </CreateSchemaPageContextProvider>
  );
}
