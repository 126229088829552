import { gql } from '@apollo/client';
import schema from '../schema';
import schemaVersion from '../schemaVersion';

export const mutation = gql`
  mutation InsertSchema($schemas_insert_input: schemas_insert_input!) {
    insert_schemas_one(object: $schemas_insert_input) {
      ...schema
      schema_versions {
        ...schemaVersion
      }
    }
  }
  ${schema}
  ${schemaVersion}
`;

export function buildVariables({ name, description, jsonSchema }) {
  return {
    schemas_insert_input: {
      name,
      description,
      schema_versions: {
        data: {
          version: 1,
          jsonSchema,
        },
      },
    },
  };
}
