import React from 'react';
import { routes } from 'app/constants';
import { MrAppLayout, MrAppMain } from '@ion/components';
import AppHeader from '../components/app-header';
import SideNav from '../components/side-nav';
import RedirectFeatureGate from '../launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from '../launch-darkly/featureFlags';

export default function SchemasPage() {
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <MrAppLayout
        title="Schemas"
        header={<AppHeader icon="schemas" title="Schemas" route={routes.schemas} />}
        sideNav={<SideNav />}
      >
        <MrAppMain />
      </MrAppLayout>
    </RedirectFeatureGate>
  );
}
