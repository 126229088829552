import React from 'react';
import pt from 'prop-types';
import { routes } from 'app/constants';
import { MrAppLayout, MrAppMain } from '@ion/components';
import { Grid } from '@metarouter-private/mr-mui';
import AppHeader from '../../components/app-header';
import SideNav from '../../components/side-nav';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import SchemaCodeEditor from './schema-code-editor';
import SchemaTextFields from './schema-text-fields';
import { useValidateSchema } from '../hooks/useValidateSchema';

export default function BaseSchemaPage({ name, description, jsonSchema, title, submitHandler }) {
  console.log('submitHandler:', submitHandler);
  const validateJson = useValidateSchema();

  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <MrAppLayout
        title={title}
        header={<AppHeader icon="schemas" title="Schemas" subTitle={title} route={routes.schemas} />}
        sideNav={<SideNav />}
      >
        <MrAppMain>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SchemaTextFields name={name} description={description} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SchemaCodeEditor
                    name="jsonSchema"
                    title="JSON Schema"
                    defaultValue={jsonSchema}
                    onChange={validateJson}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SchemaCodeEditor name="exampleEvent" title="Example Event" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MrAppMain>
      </MrAppLayout>
    </RedirectFeatureGate>
  );
}

BaseSchemaPage.propTypes = {
  name: pt.string,
  description: pt.string,
  jsonSchema: pt.string,
  title: pt.string,
  submitHandler: pt.func.isRequired,
};
