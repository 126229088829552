import React from 'react';
import { useOpen } from '@ion/components';
import { Button } from '@metarouter-private/mr-mui';
import EventDrawer from './EventDrawer';
import base64 from 'base-64';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

export default function EventCell({ title, encodedValue, linkLabel = 'View', lang = 'json' }) {
  const [isOpen, onOpen, onClose] = useOpen();
  const event = base64.decode(encodedValue);
  const disabled = isEmpty(event);

  return (
    <>
      <Button variant="text" onClick={onOpen} disabled={disabled}>
        {linkLabel}
      </Button>
      {isOpen && <EventDrawer title={title} onClose={onClose} event={event} open={isOpen} lang={lang} />}
    </>
  );
}

EventCell.propTypes = {
  title: PropTypes.string.isRequired,
  encodedValue: PropTypes.string.isRequired,
  linkLabel: PropTypes.string,
  lang: PropTypes.string,
};
