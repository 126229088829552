import React from 'react';
import { routes } from 'app/constants';
import { MrAppLayout, MrHeaderTabs, MrTab, MrTabList, MrTabPanel } from '@ion/components';
import AppHeader from '../components/app-header';
import SideNav from '../components/side-nav';
import useFeatureEnabled from '../launch-darkly/use-feature-enabled';
import EventDebuggerPage from './event-debugger';
import EventSchemasPage from './event-schemas';

export default function EventMonitoringPage() {
  const { eventDebuggerMvpEnabled } = useFeatureEnabled('eventDebuggerMvp');
  return (
    <MrAppLayout
      title="Event Monitoring"
      header={<AppHeader icon="schemaReport" title="Event Monitoring" route={routes.eventMonitoring} />}
      sideNav={<SideNav />}
    >
      <MrHeaderTabs>
        <MrTabList>
          <MrTab>Schema Report</MrTab>
          {eventDebuggerMvpEnabled && <MrTab>Event Debugger</MrTab>}
        </MrTabList>

        <MrTabPanel>
          <EventSchemasPage />
        </MrTabPanel>
        {eventDebuggerMvpEnabled && (
          <MrTabPanel>
            <EventDebuggerPage />
          </MrTabPanel>
        )}
      </MrHeaderTabs>
    </MrAppLayout>
  );
}
