import React from 'react';
import pt from 'prop-types';
import { Grid, TextField } from '@metarouter-private/mr-mui';

const SchemaTextFields = ({ name, description }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField defaultValue={name} label="Schema Name" fullWidth required />
      </Grid>
      <Grid item xs={8}>
        <TextField defaultValue={description} label="Description" fullWidth />
      </Grid>
    </Grid>
  );
};

SchemaTextFields.propTypes = {
  name: pt.string,
  description: pt.string,
};

export default SchemaTextFields;
