import React from 'react';
import { Button, Grid, RefreshIcon } from '@metarouter-private/mr-mui';
import ClusterSelect from '@ion/app/src/components/cluster-select';
import { useEventDebuggerContext } from '../context/EventDebuggerPageContextProvider';

const EventDebuggerPageHeader = () => {
  const { setSelectedCluster, setRefreshedAt } = useEventDebuggerContext();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={2}>
        <ClusterSelect onChange={setSelectedCluster} />
      </Grid>
      <Grid item xs={2}>
        <Button size="large" onClick={() => setRefreshedAt(Date.now())} startIcon={<RefreshIcon />}>
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
};

export default EventDebuggerPageHeader;
