import React from 'react';
import { Stack } from '@metarouter-private/mr-mui';
import { useTrackPage } from '@ion/api';
import { MrAppMain } from '@ion/components';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import EventDebuggerPageContextProvider from './context/EventDebuggerPageContextProvider';
import EventDebuggerPageHeader from './header';
import EventDebuggerTable from './event-debugger-table/EventDebuggerTable';

// ND - Tech Debt - Settings has a weird hack to make the page look normal wihin a Tab.
// all of this junk needs to be refactored. At least it's pulling from the same place.
import settingsStyles from '../../settings/index.module.scss';

const EventDebuggerPage = () => {
  useTrackPage('event_debugger_page_view');

  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.eventDebuggerMvp}>
      <MrAppMain className={settingsStyles.main}>
        <EventDebuggerPageContextProvider>
          <Stack spacing={2}>
            <EventDebuggerPageHeader />
            <EventDebuggerTable />
          </Stack>
        </EventDebuggerPageContextProvider>
      </MrAppMain>
    </RedirectFeatureGate>
  );
};

export default EventDebuggerPage;
